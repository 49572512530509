<template>
  <div
    class="features"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="features__wrapper">
      <div class="feature" @click="$router.push('/pickup/atm')">
        <img
          class="feature__img"
          src="../../assets/images/send-money-icon.svg"
          alt="send-money-icon"
        />
        <h4 class="feature__heading">ATM</h4>
        <p class="feature__text">Pick cash from the ATM</p>
      </div>
      <div class="feature" @click="$router.push('/pickup/in-branch')">
        <img
          class="feature__img"
          src="../../assets/images/products-icon.svg"
          alt="products-icon"
        />
        <h4 class="feature__heading">In-Branch</h4>
        <p class="feature__text">Pick cash at a branch instead</p>
      </div>
    </div>

    <!-- <app-pin-modal
      ref="otpInput"
      v-if="showPinModal"
      :showPinModal="showPinModal"
      @close="showPinModal = false"
      @userPin="setUserPin"
    ></app-pin-modal> -->
  </div>
</template>

<script>
// import api from "@/api/api.js";
import { mapState } from "vuex";
// import PinModal from "@/widgets/PinModal.vue";

export default {
  // components: {
  //   "app-pin-modal": PinModal,
  // },
  computed: mapState(["user"]),
  data() {
    return {
      loading: false,
      showPinModal: false
    };
  },
  // watch: {
  //   showPinModal: function(newValue) {
  //     if (newValue === true) {
  //       this.$nextTick(() => {
  //         this.fixChromeMask();
  //       });
  //     }
  //   },
  // },
  methods: {
    // fixChromeMask() {
    //   // Modal mask fix for chrome
    //   this.$refs.otpInput.$el.children[0].setAttribute(
    //     "autocomplete",
    //     "new-password"
    //   );
    //   const inputs = this.$refs.otpInput.$el.querySelectorAll(".otp-input");
    //   inputs.forEach((i) => i.setAttribute("autocomplete", "new-password"));
    // },
    // setUserPin(value) {
    //   this.showPinModal = false;
    //   let payload = {
    //     userId: this.user.profileInfo.SMID,
    //     pin: value,
    //   };
    //   this.loading = true;
    //   api
    //     .handlePinValidation(payload)
    //     .then((response) => {
    //       this.loading = false;
    //       let responseStatus = response.Status;
    //       let responseMessage = response.Message;
    //       if (responseStatus) {
    //         let feedback = response.Data;
    //         if (feedback.isvalid) {
    //           this.$router.push("/support/update-questions");
    //         }
    //       } else {
    //         this.$message({
    //           showClose: true,
    //           message: `${responseMessage.charAt(0).toUpperCase() +
    //             responseMessage.slice(1)}`,
    //           type: "error",
    //           duration: 10000,
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       this.loading = false;
    //       this.$message({
    //         showClose: true,
    //         message: `${error}`,
    //         type: "error",
    //         duration: 10000,
    //       });
    //     });
    // },
  }
};
</script>

<style lang="scss" scoped>
.features {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  text-align: center;
  padding: 2rem;
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    gap: 3rem;
  }
  .feature {
    // flex: 1;
    background: #ffffff;
    box-shadow: 0px 0.4rem 1rem rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &__img {
      margin-bottom: 1rem;
    }
    &__heading {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      letter-spacing: 0.05em;
      color: #000000;
      margin-bottom: 1rem;
    }
    &__text {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.9rem;
      letter-spacing: 0.05em;
      color: #000000;
    }
    &:hover,
    &:focus {
      transform: scale(1.01) translateY(-2px);
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.06);
    }
  }
}
</style>
